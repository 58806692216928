<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#3F033B"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12 py-4>
        <v-card
          tile
          elevation="0"
          class="rounded-lg pb-2"
          :color="$route.name == 'Dashboard' ? 'transparent' : '#ffffff'"
          :flat="$route.name == 'Dashboard' ? true : false"
        >
          <v-layout wrap pa-4 >
            <v-flex xs12 sm12 pr-2>
              <v-layout wrap>
                <v-flex xs8 class="mainHeader2">Gold Return</v-flex>
                <v-spacer></v-spacer>

<v-btn
icon
@click="show = !show"
>
<v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
</v-btn>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-expand-transition>
      <div v-show="show">
          <v-layout wrap px-4>
            <v-flex xs12 sm3 md2 pr-2>
              <v-layout wrap>
                <v-flex xs12 class="tablefont0" pr-2> <span>Bill.No</span></v-flex>
                <v-flex xs12 pr-2>
                  <v-autocomplete
                color="#b234a9"
                :items="SearchList"
                v-model="BillNo"
                placeholder="Select Bill"
                item-text="billNo"
                item-value="_id"
                outlined clearable
                hide-details
              dense
              @input="getBill()"
              ></v-autocomplete>
                  <!-- <v-text-field
                    v-model="BillNo"
                    outlined @change="getBill()"
                    type="text"
                    color="#b234a9"
                    placeholder="Bill No."
                    dense
                    hide-details
                  ></v-text-field> -->
              </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm3 md2 pr-2>
              <v-layout wrap>
                <v-flex xs12 pr-2 class="tablefont0"> <span>Date</span></v-flex>
                <v-flex xs12 pr-2>
                  <v-text-field
                    outlined
                    v-model="date"
                    color="#b234a9"
                    placeholder="Date"
                    dense disabled
                    hide-details
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm3 md2 pr-2>
              <v-layout wrap>
                <v-flex xs12 class="tablefont0" pr-2> <span>Customer Name</span></v-flex>
                <v-flex xs12 pr-2>
                  <v-text-field
                    outlined disabled
                    v-model="saledata.customerName"
                    type="text"
                    color="#b234a9"
                    placeholder="Customer Name "
                    dense
                    hide-details
                  ></v-text-field>
              </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm3 md2 pr-2>
              <v-layout wrap>
                <v-flex xs12 class="tablefont0" pr-2>
                  <span>Mobile Number</span></v-flex
                >
                <v-flex xs12 pr-2>
                  <v-text-field
                    outlined 
                    v-model="saledata.mobileNumber"
                    type="number" disabled
                    color="#b234a9"
                    placeholder="Mobile Number"
                    dense
                    hide-details
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
           
            <v-flex xs12 sm3 md2 pr-2>
              <v-layout wrap>
                <v-flex xs12 class="tablefont0" pr-2>
                  <span>Salesman Code</span></v-flex
                >
                <v-flex xs12 pr-2>
                  <v-text-field
                    disabled
                    outlined 
                    v-model="saledata.staffCode"
                    color="#b234a9"
                    placeholder="Salesman Code"
                    dense
                    hide-details
                  ></v-text-field
                ></v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm3 md2 pr-2>
              <v-layout wrap>
                <v-flex xs12 pr-2 class="tablefont0"> <span>GoldRate</span></v-flex>
                <v-flex xs12 pr-2>
                  <v-text-field
                    disabled
                    v-model="goldRate"
                    outlined
                    type="number"
                    color="#b234a9"
                    placeholder="GoldRate"
                    dense
                    hide-details
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>

          <!---------------------Table-------------------------->
          <!-- v-if="check2==true" -->
          <v-layout px-4 py-4 wrap v-if="check2==true">
            <v-flex xs12 v-if="List">
              <v-card  v-if="List.length>0" class="pb-2">
                <v-layout wrap>
                  <v-flex xs12 >
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left tablefont"><b>Action</b></th>
                            <!-- <th class="text-left tablefont"><b>Sno.</b></th> -->
                            <th class="text-left tablefont">
                              <b>Item</b>
                            </th>
                            <th class="text-left tablefont"><b>HSN</b></th>
                            <th class="text-left tablefont" style="min-width: 20px;">
                              <b>Qty</b>
                            </th>
                            <th class="text-left tablefont"><b>Gr.wt(gm)</b></th>
                            <th class="text-left tablefont"><b>St.wt(gm)</b></th>
                            <th class="text-left tablefont"><b>Net.wt(gm)</b></th>
                            <!-- <th class="text-left tablefont"><b>St.pr(₹)</b></th> -->
                            <th class="text-left tablefont"><b>Total(₹)</b></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(value, i) in List" :key="i" class="table">
                            <td>
                              <v-checkbox
                              :disabled="(value.designId.countOrSplit=='Count' && value.quantityoriginal<value.quantity) || (value.designId.countOrSplit=='Count' && value.quantity<1) ?true:false"
                                @click="selectThis(value.checkbox, value._id,value)"
                                v-model="value.checkbox"
                                hide-details
                                      color="#3F053C"
                              ></v-checkbox>
                            </td>
                            <td>
                              <span v-if="value.designId"
                                >{{ value.designId.name }}-{{
                                  value.itemId.name
                                }}</span
                              >
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.designId.HSN">
                                {{ value.designId.HSN.hsnCode }}</span
                              >
                              <span v-else>-</span>
                            </td>
                            <td>
                              <v-text-field
                                :disabled="
                                  value.checkbox == true || value.designId.countOrSplit=='Split' ? true : false
                                "
                                placeholder="0"
                                color="#b234a9"
                                type="number"
                                solo
                                flat 
                                :maxValue="value.quantityoriginal"
                                :rules="[maxValueRule(value.quantityoriginal), minValueRule]"
                                @change="cal7(i,value._id)"
                                v-model="value.quantity"
                                dense
                                hide-details="auto"
                              ></v-text-field>
                            </td>
                            <td>
                              <span v-if="value.grossWeight">{{
                                value.grossWeight
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td>
                              <span v-if="value.stoneWeight">{{
                                value.stoneWeight
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td>
                              <span v-if="value.netWeight">{{
                                value.netWeight
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <!-- <td>
                              <span v-if="value.stonePrice">{{
                                value.stonePrice
                              }}</span>
                              <span v-else>0</span>
                            </td> -->
                            <td>
                              <span v-if="value.itemTotalAmount">{{
                                value.itemTotalAmount.toFixed(2)
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <td>
                             
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout wrap>
            <v-flex xs12 class="subhed2">
              <v-layout justify-end v-if="check2==true">
                <v-flex xs12 sm4 md2 px-1>
                  <v-layout wrap py-2 >
                <v-flex xs12 class="subhed2" text-left pr-4 align-self-center v-if="returnNetWt">
                  Net Weight(gm) 
                </v-flex>
                <v-flex xs12 pr-5 v-if="returnNetWt">
                  <v-card
                    outlined
                    class="pa-2"
                    
                  >
                  {{(returnNetWt).toFixed(2)}}</v-card
                ></v-flex>
              </v-layout>
                </v-flex>
                <v-flex xs12 sm4 md2 px-1>
                  <v-layout wrap py-2 pb-4 v-if="returnTotal">
                <v-flex xs12 class="subhed2" text-left pr-4 align-self-center>
                  Net Amount(₹)
                </v-flex>
                <v-flex xs12 pr-5>
                  <v-card
                    outlined
                    class="pa-2"
                    
                  >
                  {{(returnTotal).toFixed(2)}}</v-card
                ></v-flex>
              </v-layout>
                </v-flex>
              </v-layout>
             
           
            </v-flex>
          </v-layout>
        </div>
            </v-expand-transition>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["title2"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      timeout: 2000,
      msg: null,
      date: null,
      juwellaryId: localStorage.getItem("juwellaryId"),
    taxType:localStorage.getItem("GSTstatus"),
      BillNo:null,
      List: [],
      List1:[],
      saledata: [],
      show: false,
      selected: [],
      selectedItems: [],
      check2:false,
      flag1: false,
      sflag: true,
      returnTotal:null,
      returnNetWt:null,
      newId:null,
      newid2:"",
      newList:[],
      goldRate:"",
      SearchList:[],
    };
  },
  computed: {
    goldRate1() {
      return localStorage.getItem("goldRate");
    },
  },
  mounted() {
    this.getReturns();
  //   this.purchaseItem.boardingRate=this.goldRate1;
  },
  watch: {
  
    title2() {
      this.salesId=this.title2._id;
      console.log("saleid==",this.salesId)
    },
    salesId(){
      this.getData();
    },
    selected() {
      // cal7(i,value._id)
      this.calctotal();
      console.log("4")
    
    },
    returnTotal(){
      console.log("selectedItems=",this.selectedItems)
      console.log("11=",this.newId)
      this.$emit("stepper", {
        ref: "returnItems",
        returngoldRate:this.goldRate,
        returnTotalAmt:this.returnTotal,
        returnNetWt:this.returnNetWt,
        returndata: this.selectedItems,
        returnsalesId: this.newId,
        billNo:this.BillNo,
        customerName:this.saledata.customerName,
        staffCode:this.saledata.staffCode,
        check1: true,
      });
    },
  },
  methods: {
    maxValueRule(item) {
      return (value) => {
        if (value > item) {
          return `Maximum value is ${item}`;
        }
        return true;
      };
    },
    minValueRule: (value) => {
      if (value < 1) {
        return 'Minimum value is 1'
      } else {
        return true
      }
    },
    cal7(i,id) {
      if(this.List[i].quantity)
      this.check2=false;
      //calculate netweight
      this.List[i].netWeight=0;
      console.log("quantity==",this.List[i].quantity)
      console.log("itemPerWeight==",this.List[i].itemPerWeight)

      this.List[i].stoneWeight=this.List[i].quantity*this.List[i].purchaseItemId.stoneWeight;
      console.log("newstonewt==",this.List[i].stoneWeight)

      this.List[i].netWeight=this.List[i].quantity*this.List[i].itemPerWeight
      console.log("newtwt==",this.List[i].netWeight)
    
      this.List[i].grossWeight=this.List[i].netWeight+this.List[i].stoneWeight
      console.log("stoneweight==",this.List[i].purchaseItemId.stoneWeight)
      console.log("grossWeight==",this.List[i].grossWeight)
   
        this.List[i].itemTotalAmount =
          this.goldRate * this.List[i].netWeight;
        console.log("goldRate=", this.goldRate);
        console.log("netWeight=", this.List[i].netWeight);
        console.log("total=", this.List[i].itemTotalAmount);

//updating array
      this.newList = this.List;
      console.log("newarray=", this.newList);
      this.List = this.newList
      this.check2=true;
      this.newid2=id;
    },
markAll() {
      if (this.isSelectAll) {
        this.selected = [];
        this.sflag = false;
        for (let i = 0; i < this.savedList.length; i++) {
          this.savedList[i].checkbox = true;
          this.selected.push(this.savedList[i]._id);
        }
        console.log("markeD", this.selected);
        // console.log("Ddata=", this.isSelectAll);
        this.sflag = true;
      } else {
        this.sflag = false;
        for (let i = 0; i < this.savedList.length; i++) {
          this.savedList[i].checkbox = false;
          this.selected = [];
        }
        console.log("Rem", this.selected);
        // console.log("Ddata=", this.isSelectAll);
        this.sflag = true;
      }
    },
    selectThis(val, id, items1) {
      this.isSelectAll = false;
      // console.log("items1=", items1);
      if (val == true) {
        if (this.selected.length > 0) {
          if (!id.includes(this.selected)) {
            this.selected.push(id);
            this.selectedItems.push(items1);
            console.log("ll=");
          }
        } else {
          this.selected.push(id);
          this.selectedItems.push(items1);

        }
      } else {
        var ar = this.selected;
        var aritem = this.selectedItems;
        this.selected = ar.filter((x) => x != id);
        this.selectedItems = aritem.filter((x) => x._id.toString() != id.toString());
      }

      console.log("selected id==", this.selected);
      console.log("selectedItems==", this.selectedItems);
      console.log("savedListCopy==", this.savedListCopy);

    },
calctotal() {
      axios({
        method: "Post",
        url: "/returnitem/calculation",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          currentReturnItems: this.selectedItems,
          salesId: this.salesId,
        },
      })
        .then((response) => {
          if (response.data.status) {
            // this.selected=[];
            this.returnTotal = response.data.totalAmount;
            console.log("this.returnTotal==",this.returnTotal)
            this.returnNetWt = response.data.totalnetweight;
            
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getReturns() {
      axios({
        url: "/return/bill/searchlist",
        method: "get",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.SearchList = response.data.saledata;
           
          } else {
           
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getBill() {
      this.List1=[];
      this.List=[];
    this.goldRate=this.goldRate1;

      axios({
        url: "/return/bill/search",
        method: "get",
        params: {
          saleId: this.BillNo,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.List1 = response.data.data;
            // this.List = response.data.data;

            this.saledata = response.data.saledata;
            this.testList();
            this.check1=true;
            this.check2=true;
            if (this.List1.length > 0) {
              this.flag1 = true;
            } else {
              this.flag1 = false;
            }
            this.newId = response.data.saledata._id;
            var newdate = response.data.saledata.create_date;
            this.date=this.formatDate(newdate);
            console.log("date=",this.date)
          } else {
            this.check2=false;
            this.goldRate="";
            this.List = [];
            this.saledata = [];
            this.newId = "";
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    testList(){
      for(var i=0;i<this.List1.length;i++){
      this.List1[i].quantityoriginal =
        this.List1[i].quantity;
      }
      this.List=this.List1;
      console.log("List1==", this.List1);

    },
    updateReturnQty(newid2) {
      axios({
        method: "Post",
        url: "/return/edit",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          items:this.newList,
          id:newid2,
        },
      })
        .then((response) => {
          if (response.data.status) {
            // this.selected=[];
            this.returnTotal = response.data.totalAmount;
            console.log("this.returnTotal==",this.returnTotal)
            this.returnNetWt = response.data.totalnetweight;
            
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      //  +
      // " , " +
      // hours +
      // ":" +
      // minutes +
      // " " +
      // ampm;

      return strTime;
    },
  },
};
</script>
